@if(currentScreenSize != 'mobile'){
  <div class="page-promotion">
    <app-header-simple />
    <div class="page-inner">
      <div class="container-xxl row m-auto">
        <div class="promotion-body">
          <div class="sidebar-column">
            <app-sidebar-menu  />
          </div>
          <div class="col-12 pl-0 promotion-container content-container" id="promotion-container">
            <div class="promotion-inner h-100" >
              <div class="promotion-title-custom">
                <div class="title-promotion">Profil</div>
              </div>
              <div class="page-inner-container">
                <div *ngIf="part === 'default'">
                  <div class="contact-container row px-4 mt-4">
                    <div class="information-container w-100 p3">
                      <div class="heading-container row p-2">
                        <div class="title-section col-8">
                          <p class="fw-bolder pt-2 mb-1 inter-bold title-profile">INFORMASI PRIBADI</p>
                          <p class="opacity-50 mb-1 subtitle">
                            Kelola nama dan informasi akun Anda. Detail pribadi ini
                            bersifat pribadi dan tidak akan ditampilkan kepada pengguna
                            lain.
                          </p>
                        </div>
                        <div
                          class="col-4 d-flex align-items-center justify-content-end"
                        >
                        @if (isEditable) {
                          <button
                            class="btn btn-primary btn-lg btn-detail edit-button float-end w-50"
                            (click)="changePart('profile')"
                          >
                            Edit
                          </button>
                        }
                        </div>
                      </div>
                      <div class="edit-profile row">
                        <div class="col-6">
                          <mat-form-field appearance="fill" class="w-100">
                            <mat-label>First Name</mat-label>
                            <input matInput placeholder="Please Enter First Name" [(ngModel)]="profile.first_name" readonly="true"/>
                            <mat-icon matSuffix>person_pin</mat-icon>
                          </mat-form-field>
                        </div>
                        <div class="col-6">
                          <mat-form-field appearance="fill" class="w-100">
                            <mat-label>Last Name</mat-label>
                            <input matInput placeholder="Please Enter Last Name" [(ngModel)]="profile.last_name" readonly="true"/>
                            <mat-icon matSuffix>person_pin</mat-icon>
                          </mat-form-field>
                        </div>
                        <div class="col-6">
                          <mat-form-field appearance="fill" class="w-100">
                            <mat-label>Email</mat-label>
                            <input matInput placeholder="Please Enter Email" [(ngModel)]="profile.email" readonly="true"/>
                            <mat-icon matSuffix>mail_outline</mat-icon>
                          </mat-form-field>
                        </div>
                        <div class="col-6">
                          <mat-form-field appearance="fill" class="w-100">
                            <mat-label>Nomor Telepon</mat-label>
                            <input matInput placeholder="Please Enter Phone Number"  [(ngModel)]="profile.mobile_phone_number" readonly="true"/>
                            <mat-icon matSuffix>phone</mat-icon>
                          </mat-form-field>
                        </div>
                      </div>
                    </div>
                    <div class="information-container w-100 p3 mt-4">
                      <div class="heading-container row p-2">
                        <div class="title-section col-8">
                          <p class="fw-bolder pt-2 mb-1 inter-bold title-profile">PASSWORD & PIN KEAMANAN</p>
                          <p class="opacity-50 mb-1 subtitle">
                            Demi keamanan Anda, kami sangat menyarankan Anda memilih
                            kata sandi yang unik yang tidak Anda gunakan untuk akun
                            lainnya.
                          </p>
                        </div>
                      </div>
                      <div class="edit-profile">
                        <div class="row">
                          <div class="col-10">
                            <mat-form-field class="w-100">
                              <mat-label>Password</mat-label>
                              <input matInput [type]="hide ? 'password' : 'text'" readonly/>
                              <button
                                mat-icon-button
                                matSuffix
                                (click)="hide = !hide"
                                [attr.aria-label]="'Hide password'"
                                [attr.aria-pressed]="hide"
                              >
                                <mat-icon>{{
                                  hide ? "visibility_off" : "visibility"
                                }}</mat-icon>
                              </button>
                            </mat-form-field>
                          </div>
                          <div class="col-2">
                            <button
                            class="btn btn-primary btn-lg btn-detail pin-button edit-button float-end "
                            (click)="changePart('password')"
                          >
                            Change
                          </button>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-10">
                            <mat-form-field class="w-100">
                              <mat-label>Pin</mat-label>
                              <input matInput [type]="hidePin ? 'password' : 'text'" readonly="true"/>
                              <button
                                mat-icon-button
                                matSuffix
                                (click)="hidePin = !hidePin"
                                [attr.aria-label]="'Hide Pin'"
                                [attr.aria-pressed]="hidePin"
                              >
                                <mat-icon>{{
                                  hide ? "visibility_off" : "visibility"
                                }}</mat-icon>
                              </button>
                            </mat-form-field>
                          </div>
                          <div class="col-2">
                            <button
                            class="btn btn-primary btn-lg btn-detail pin-button edit-button float-end "
                            (click)="changePart('pin')"
                          >
                            Change
                          </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                @if(part === 'profile') {
                  <app-profile-information></app-profile-information>
                }
                @if(part === 'password') {
                  <app-password></app-password>
                }
                @if (part === 'pin') {
                  <app-pin></app-pin>
                }
              </div>

            </div>

          </div>
        </div>
      </div>
    </div>
    <app-footer [data]="footerData"></app-footer>
  </div>

} @else {
  <app-profile-mobile></app-profile-mobile>
}
