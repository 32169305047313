

@if(data.length>0){
    <swiper-container init="false" #swiperNewsTicker class="swiper-container swiper-newsticker">
        @for (item of data; track item.order;) {
            @if(item['title'] !== ''){
                <swiper-slide class="swiper-item newsticker-item">
                    @if(item['asset_url'] !== '' && item['asset_url'] !== '#') {
                    @if(item.link_type === 1){
                        <span #cardTitleNews id="cardTitleNews" class="newsticker-item">
                            @if(cardTitleNews && finished){
                                <a [routerLink]="['/promotion']" [queryParams]="{ part: item.article }" #cardTitleTextNews id="cardTitleTextNews" [ngClass]="cardTitleNews.getBoundingClientRect().width < (cardTitleTextNews.getBoundingClientRect().width) ? 'animation-news': ''">
                                    {{item['title']}}
                                </a>
                            }
                        </span>
                    }@else{
                        <!-- <img [src]="item.asset_url" [title]="item.title" /> -->
                        <span #cardTitleNews id="cardTitleNews" class="newsticker-item">
                            @if(cardTitleNews && finished){
                                <a [href]="item.target_url" target="_blank" #cardTitleTextNews id="cardTitleTextNews" [ngClass]="cardTitleNews.getBoundingClientRect().width < cardTitleTextNews.getBoundingClientRect().width ? 'animation-news': ''">
                                    {{item['title']}}
                                </a>
                            }
                        </span>
                    }
                    }
                </swiper-slide>
            }
        }
    </swiper-container>
}
