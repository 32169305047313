<div #scrollHome class="page-home">
<app-header-simple activeMenu="home" />
    <div class="d-flex container-xxl m-auto home-top">
      @if(!islogin){
        <app-login-home class="d-none d-md-block"></app-login-home>
      }
      <app-slideshow-home [data]="slideshowJSONData" />
    </div>
    <div class="row home-middle container-xxl m-auto">
      <app-jackpot-tablet [value]="5000000" />
      @if(currentScreenSize === 'customTablet'){
        @if(!hideLiveScore){
          <div class="big-match-container">
            <div class="big-match">
              <div class="big-match-header">BIG MATCH</div>
              <div class="big-match-body">
                <p class="title">{{bigMatchData.league_name}}</p>
                <div class="d-flex align-items-center justify-space-between">
                  <div>
                    <img
                      [src]="bigMatchData.home_team_image"
                      width="92"
                      height="92"
                    />
                    <p class="big-match-team-name">{{bigMatchData.home_team_name}}</p>
                  </div>
                  <div class="w-25 d-flex flex-column align-items-center mx-3">
                    <span class="d-flex align-items-center">
                      @if(!bigMatchData.live){
                        VS
                      } @else {
                        <p class="big-match-score" [ngStyle]="{'opacity': getOpacity(bigMatchData.home_score, bigMatchData.away_score, 'home')}">{{bigMatchData.home_score}}</p>
                        <p class="big-match-score mx-2">:</p>
                        <p class="big-match-score" [ngStyle]="{'opacity': getOpacity(bigMatchData.home_score, bigMatchData.away_score, 'away')}">{{bigMatchData.away_score}}</p>
                      }
                    </span>
  
                    <div class="d-flex align-items-center text-white my-2">
                      <img
                        src="../template/green/assets/images/ic-timer.png"
                        width="11"
                        height="12"
                      />
                      @if(bigMatchData.phase == 7){
                        <span class="min-live">Penalty</span>
                      } @else if(bigMatchData.phase == 9){
                        <span class="min-live">Fulltime</span>
                      } @else if(bigMatchData.phase == 3 || bigMatchData.phase == 5){
                        <span class="min-live">Halftime</span>
                      }@else {
                        <span #shakeSpan class="min-live">{{ getMinuteDifference(bigMatchData.match_start_at) }}’</span>
                      }
                    </div>
                    <img
                      src="../../../template/green/assets/images/Live.png"
                      width="41"
                      height="16"
                    />
                  </div>
                  <div>
                    <img
                    [src]="bigMatchData.away_team_image"
                      width="92"
                      height="92"
                    />
                    <p class="big-match-team-name">{{bigMatchData.away_team_name}}</p>
                  </div>
                </div>
                <button class="w-100 place-bet">BET NOW</button>
                <div
                  class="d-flex justify-content-center align-items-center mt-1 big-match-list-logo"
                >
  
                  <img
                    src="../../../template/green/assets/images/SBOBET_Logo.png"
                    width="66"
                    height="16"
                  />
                  <img
                    src="../../../template/green/assets/images/saba_sport.png"
                    width="42"
                    height="16"
                  />
                  <!--   -->
                  <img
                    src="../../../template/green/assets/images/w_entertainment.png"
                    width="81"
                    height="31"
                  />
                </div>
              </div>
            </div>
            <div class="list-promotion-container">
              <div class="list-promotion">
                @if(promoJSONData.length > 0) {
                  <swiper-container init="false" #swiperContainer class="swiper-container">
                    @for (item of promoJSONData; track item;) {
                    <swiper-slide class="swiper-item">
  
                      @if(item.thumbnail_url !== '') {
                        <a [routerLink]="['/promotion']" [queryParams]="{ part: item._id }" class="w-100">
                          <img [src]="item.thumbnail_url" [alt]="item.title" class="w-100" priority/>
                        </a>
                      }
                    </swiper-slide>
                    }
                  </swiper-container>
                <a href="/promotion" class="w-100 see-all-promo">See All Promo</a>
                }
              </div>
            </div>
  
          </div>
        } @else {
          <div class="list-tablet-promotion-container mt-4">
            <div class="list-tablet-promotion">
              @if(promoJSONData.length > 0) {
              <swiper-container init="false" #swiperContainerHorizontal class="swiper-tablet-container">
                @for (item of promoJSONData; track item;) {
                <swiper-slide class="swiper-tablet-item">
            
                  @if(item.thumbnail_url !== '') {
                  <a [routerLink]="['/promotion']" [queryParams]="{ part: item._id }">
                    <img class="img-medium" [src]="item.thumbnail_url" [alt]="item.title" priority />
                  </a>
                  }
                </swiper-slide>
                }
              </swiper-container>
              <a href="/promotion" class="w-100 see-all-promo">See All Promo</a>
              }
            </div>
          </div>
        }
      } @else {
        <div class="list-tablet-promotion-container mt-4">
          <div class="list-tablet-promotion">
            @if(promoJSONData.length > 0) {
              <swiper-container init="false" #swiperContainerHorizontal class="swiper-tablet-container">
                @for (item of promoJSONData; track item;) {
                <swiper-slide class="swiper-tablet-item">

                  @if(item.thumbnail_url !== '') {
                    <a [routerLink]="['/promotion']" [queryParams]="{ part: item._id }" >
                      <img [src]="item.thumbnail_url" [alt]="item.title"  priority/>
                    </a>
                  }
                </swiper-slide>
                }
              </swiper-container>
            <a href="/promotion" class="w-100 see-all-promo">See All Promo</a>
            }
          </div>
          @if(!hideLiveScore){
            <div class="big-match-tablet-container">
              <div class="big-match-left">
                <img src="../../../../template/green/assets/images/big-match.webp" class="img-big-match">
              </div>
              <div class="big-match-right">
                <div class="left-content">
                  <div class="league-name">
                    {{bigMatchData.league_name}}
                  </div>
                  <div class="big-match-time">
                    <img
                      src="../../../template/green/assets/images/Live.png"
                      width="41"
                      height="16"
                    />
                    <div class="d-flex align-items-center text-white my-2">
                      <img
                        src="../template/green/assets/images/ic-timer.png"
                        width="11"
                        height="12"
                      />
                      @if(bigMatchData.phase == 7){
                        <span class="min-live">Penalty</span>
                      } @else if(bigMatchData.phase == 9){
                        <span class="min-live">Fulltime</span>
                      } @else if(bigMatchData.phase == 3 || bigMatchData.phase == 5){
                        <span class="min-live">Halftime</span>
                      }@else {
                        <span #shakeSpan class="min-live">{{ getMinuteDifference(bigMatchData.match_start_at) }}’</span>
                      }
                    </div>
  
                  </div>
                </div>
                <div class="middle-content">
                  <div class="d-flex align-items-center justify-space-between">
                    <div>
                      <img
                        [src]="bigMatchData.home_team_image"
                        width="92"
                        height="92"
                      />
                    </div>
                    <div class="w-25 d-flex flex-column align-items-center mx-3">
                      <span class="d-flex align-items-center">
                        @if(!bigMatchData.live){
                          VS
                        } @else {
                          <p class="big-match-score" [ngStyle]="{'opacity': getOpacity(bigMatchData.home_score, bigMatchData.away_score, 'home')}">{{bigMatchData.home_score}}</p>
                          <p class="big-match-score mx-2">:</p>
                          <p class="big-match-score" [ngStyle]="{'opacity': getOpacity(bigMatchData.home_score, bigMatchData.away_score, 'away')}">{{bigMatchData.away_score}}</p>
                        }
                      </span>
                    </div>
                    <div>
                      <img
                      [src]="bigMatchData.away_team_image"
                        width="92"
                        height="92"
                      />
                    </div>
                  </div>
                </div>
                <div class="right-content">
                  <button class="place-bet">BET NOW</button>
                </div>
              </div>
            </div>
          }
        </div>
      }

      <div class="">
        <div class="d-flex justify-content-between mt-4">
          @if(currentScreenSize === 'customTablet'){
            @if(!hideLiveScore){
              <div class="home-run-bet">
                <div
                  class="d-flex align-items-center justify-content-between home-run-bet-header"
                >
                  <p class="title-bet">RUNNING BETS</p>
                  <p class="total-bet">12</p>
                </div>
                <div
                  class="d-flex align-items-center justify-content-between home-run-bet-body"
                >
                  <img
                    src="../../../template/green/assets/images/SBOBET_Logo.png"
                    width="82"
                    height="20"
                  />
                  @for (item of betList; track item;) {
                  <div
                    class="d-flex align-items-center justify-content-between bet-detail w-100"
                  >
                    <div class="bet-detail-info">
                      <span class="d-flex align-items-center">
                        <p class="bet-info-team">{{ item.team1 }}</p>
                        <span class="bet-info-vs">vs</span>
                        <p class="bet-info-team">{{ item.team2 }}</p>
                      </span>
                      <p class="bet-info-win">Bets {{ item.winner }} WINS</p>
                    </div>
                    <p class="bet-info-number">
                      Rp {{ item.totalBet | formatNumberId }}
                    </p>
                  </div>
                  }
                </div>
                <div
                  class="d-flex align-items-center justify-content-between home-run-bet-body"
                >
                  <img
                    src="../../../template/green/assets/images/saba_sport.png"
                    width="53"
                    height="20"
                    class="mb-2"
                  />
                  @for (item of sabaBetList; track item;) {
                  <div
                    class="d-flex align-items-center justify-content-between bet-detail w-100"
                  >
                    <div class="bet-detail-info">
                      <span class="d-flex align-items-center">
                        <p class="bet-info-team">{{ item.team1 }}</p>
                        <span class="bet-info-vs">vs</span>
                        <p class="bet-info-team">{{ item.team2 }}</p>
                      </span>
                      <p class="bet-info-win">Bets {{ item.winner }} WINS</p>
                    </div>
                    <p class="bet-info-number">
                      Rp {{ item.totalBet | formatNumberId }}
                    </p>
                  </div>
                  }
                </div>
              </div>
            }
          }
          @if(!hideLiveScore){
            <div class="home-other-match">
              <div class="d-flex flex-column home-other-match-header">
                <div class="d-flex align-items-center justify-content-between">
                  <p class="title-bet">OTHER MATCHES</p>
                  <p class="title-bet">
                    <a href="#">
                      SEE ALL <fa-icon [icon]="faAngleRight"> </fa-icon>
                    </a>
                  </p>
                </div>
                <div class="latest-news">
                  <div class="latest-news-left">
                    <img
                      src="../template/green/assets/images/ic-bullhorn.png"
                      width="12"
                      height="10"
                    />
                  </div>
                  <div class="latest-news-right">
                    <div class="latest-news-right-inner">
                      <span class="newsticker-item"
                        >Contrary to popular belief, Lorem Ipsum is not simply
                        random text. It has roots in a piece of classical Latin
                        literature from 45 BC, making it over 2000 years old.
                        Richard McClintock, a Latin professor at Hampden-Sydney
                        College in Virginia, looked up one of the more obscure Latin
                        words, consectetur, from a Lorem Ipsum passage, and going
                        through the cites of the word in classical literature,
                        discovered the undoubtable source. Lorem Ipsum comes from
                        sections 1.10.32 and 1.10.33 of "de Finibus Bonorum et
                        Malorum" (The Extremes of Good and Evil) by Cicero, written
                        in 45 BC. This book is a treatise on the theory of ethics,
                        very popular during the Renaissance. The first line of Lorem
                        Ipsum, "Lorem ipsum dolor sit amet..", comes from a line in
                        section 1.10.32.</span
                      >
                      <span class="marquee-sep"></span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="d-flex flex-column home-other-match-body mt-2 w-100">
                @for (item of otherMatchData; track item;) {
                <div
                  class="detail-other-match"
                  [class.cardMatchEven]="($index + 1) % 2 === 0"
                >
                  <div class="is-live-info">
                    @if(item.live === true){
                    <div class="d-flex align-items-center text-white flex-column">
                      <div>
                        <img
                        src="../../../template/green/assets/images/Live.png"
                        width="41"
                        height="16"
                        class="is-live"
                      />
                      </div>
                      <div class="">
                        <img
                        #shakeImage
                        src="../template/green/assets/images/ic-timer.png"
                        width="11"
                        height="12"
                      />
                      @if(item.phase == 7){
                        <span class="min-live">Penalty</span>
                      } @else if(item.phase == 9){
                        <span class="min-live">Fulltime</span>
                      } @else if(item.phase == 3 || item.phase == 5){
                        <span class="min-live">Halftime</span>
                      }@else {
                        <span #shakeSpan class="min-live">{{ getMinuteDifference(item.match_start_at) }}’</span>
                      }
                      </div>
                    </div>
                    } @if(!item.live){
                    <div>
                      <p class="info-time">{{ item.match_start_at | formatTimeId }}</p>
                      <p class="info-date">{{ item.match_start_at | formatDateId }}</p>
                    </div>
                    }
                  </div>
                  <div
                    class="d-flex align-items-center justify-content-center text-white other-middle-info"
                  >
                    <div class="d-flex team-info team-info-left align-items-center">
                      <p class="team-score mb-0 mx-1">{{ item.home_team_name }}</p>
                      <img [src]="item.home_team_image" width="25" height="25" />
                    </div>
                    <p class="team-score mx-3">
                      @if(!item.live){
                        VS
                      } @else {
                        {{ item.home_score }} : {{ item.away_score }}
                      }
                    </p>
                    <div class="d-flex team-info team-info-right align-items-center">
                      <img [src]="item.away_team_image" width="25" height="25" />
                      <p class="team-score mb-0 mx-1">{{ item.away_team_name }}</p>
                    </div>
                  </div>
                  <div class="btn-place-bet">
                    <button class="place-bet">Place</button>
                  </div>
                </div>
                }
              </div>
            </div>
          }
        </div>
      </div>
    </div>

    <app-games-widget></app-games-widget>

    <app-all-games-home />
    <app-footer [data]="footerData" />
  </div>
