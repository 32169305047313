import { Inject, Injectable, NgZone, PLATFORM_ID } from '@angular/core';
import { Params, Router } from '@angular/router';
import { DataService } from './data.service';
import { SsrCookieService } from 'ngx-cookie-service-ssr';
import { isPlatformBrowser } from '@angular/common';
import { BehaviorSubject, Subscription, interval } from 'rxjs';
import { RequestService } from './request.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private loginStatus = new BehaviorSubject(false);
  private pinStatus = new BehaviorSubject(true);
  private balanceValue = new BehaviorSubject(0);
  private sessionInterval: Subscription = new Subscription();
  public getLoginStatus = this.loginStatus.asObservable();
  public getBalancevalue = this.balanceValue.asObservable();
  public sessionValue: string = '';
  public userProfileData: Record<string, any> = {};
  public userSecurityData: Record<string, any> = {};
  public userGorupData: Record<string, any> = {};
  private redirectURL: string  = '';
  private setLoginStatus(val: boolean): void {
    this.loginStatus.next(val);
  }
  public setPINStatus(val: boolean): void {
    // console.log(this.redirectURL)
    if (!val) {
      this.cookies.set('v5_il', '2', this.data.websiteConfigData.cookie_options);
      if (isPlatformBrowser(this.platformId)) {
        // window.history.go(-1)
        if(this.redirectURL){
          this.router.navigateByUrl(this.redirectURL);
        } else {
          this.router.navigate(['/']);
        }
      }
    } else {
      this.cookies.set('v5_il', '1', this.data.websiteConfigData.cookie_options);
    }
    this.pinStatus.next(val);
  }
  private setBalanceValue(val: number): void {
    this.balanceValue.next(val);
  }

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private zone: NgZone,
    private router: Router,
    private data: DataService,
    private request: RequestService,
    private cookies: SsrCookieService
  ) { }

  setLogin(res: Record<string, any>, data: any): boolean {
    // console.log(data);
    this.redirectURL = data;
    const dataSession = res['session'] as Record<string, any>;
    const dataProfile = res['profile'] as Record<string, any>;
    const dataSecurity = res['security'] as Record<string, any>;
    const dataGroup = res['group'] as Record<string, any>;

    if(this.data.websiteJSONData['configuration']['pin']){
      if(res['hide_pin']){
        this.cookies.set('v5_il', '2', this.data.websiteConfigData.cookie_options);
      }else {
        this.cookies.set('v5_il', '1', this.data.websiteConfigData.cookie_options);
      }
      //redirect
      if(data){
        this.router.navigateByUrl(data);
      } else {
        this.router.navigate(['/']);
      }
    } else {
      this.cookies.set('v5_il', '2', this.data.websiteConfigData.cookie_options);
      //redirect
      if (data) {
        this.router.navigateByUrl(data);
      } else {
        this.router.navigate(['/']);
      }
    }
    this.cookies.set('v5_s', dataSession['session'] as string, this.data.websiteConfigData.cookie_options);

    this.setUserData(res);

    this.request.getSession(res['session']['session']).subscribe({
      next: (v) => {
        // console.log(v)
        if (v['code'] === 200) {
          this.setBalanceValue(v['data']['balance']);
        }
      },
      error: (e) => {
        if (e.status === 401 || e.status === 400) {
          this.setLogout();
        }
        console.log(e);
      },
      complete: () => { }
    })

    // if (!this.websiteConfig.website.pin) {
    //   this.setPINStatus(false, { queryParams: param });
    // }
    // this.router.navigate(['/'], { queryParams: param });
    this.updateLoginStatus();
    return true;
  }

  getUserData() {
    if (isPlatformBrowser(this.platformId)) {
      // console.log('set user Data called')
      let localStorageProfile = localStorage.getItem('v5_up');
      let localStorageSecurity = localStorage.getItem('v5_us');
      let localStorageGroup = localStorage.getItem('v5_ug');
      this.userProfileData = localStorageProfile ? JSON.parse(atob(localStorageProfile)) : {};
      this.userSecurityData = localStorageSecurity ? JSON.parse(atob(localStorageSecurity)) : {};
      this.userGorupData = localStorageGroup ? JSON.parse(atob(localStorageGroup)) : {};
    }
  }

  setUserData(res: Record<string, any>) {
    const dataProfile = res['profile'] as Record<string, any>;
    const dataSecurity = res['security'] as Record<string, any>;
    const dataGroup = res['group'] as Record<string, any>;

    if (isPlatformBrowser(this.platformId)) {
      localStorage.setItem('v5_up', btoa(JSON.stringify(dataProfile)));
      localStorage.setItem('v5_us', btoa(JSON.stringify(dataSecurity)));
      localStorage.setItem('v5_ug', btoa(JSON.stringify(dataGroup)));
      this.getUserData();
    }
  }

  setLogout(): boolean {
    this.sessionInterval.unsubscribe();
    const option = this.data.websiteConfigData.cookie_options;
    // cookies delete spesific cookie TODO
    this.cookies.deleteAll(option?.path, option?.domain, option?.secure, option?.sameSite);
    localStorage.removeItem('v5_up');
    localStorage.removeItem('v5_us');
    localStorage.removeItem('v5_ug');
    this.router.navigate(['/login']);
    this.updateLoginStatus();
    return true;
  }

  private checkSession(s: string) {
    if (isPlatformBrowser(this.platformId)) {
      const isSession = this.cookies.get('v5_s');
      const isLogin = this.cookies.get('v5_il');
      if (!isSession || !isLogin) {
        this.setLogout();
      } else {
        this.sessionInterval = interval(8000).subscribe(val =>
          this.request.getSession(s).subscribe({
            next: (v) => {
              // console.log(v)
              this.sessionInterval.unsubscribe();
              if (v['code'] === 200) {
                this.checkSession(s);
                this.setBalanceValue(v['data']['balance']);
              }
            },
            error: (e) => {
              this.sessionInterval.unsubscribe();
              if (e.status === 401 || e.status === 400) {
                this.setLogout();
              }
              console.log(e);
            },
            complete: () => {}
          })
        );
      }
    }
  }
  public callSession(s: string) {
    if (isPlatformBrowser(this.platformId)) {
      const isSession = this.cookies.get('v5_s');
      const isLogin = this.cookies.get('v5_il');
      if (!isSession || !isLogin) {
        this.setLogout();
      } else {
        this.request.getSession(s).subscribe({
          next: (v) => {
            if (v['code'] === 200) {
              this.setBalanceValue(v['data']['balance']);
            }
          },
          error: (e) => {
            if (e.status === 401 || e.status === 400) {
              this.setLogout();
            }
            console.log(e);
          },
          complete: () => { }
        })
      }
    }
  }
  public updateLoginStatus(): boolean {
    const isLogin = this.cookies.get('v5_il');
    if (isLogin === '1' || isLogin === '2') {
      this.setLoginStatus(true);
      this.cookies.delete('ref');
      if (isPlatformBrowser(this.platformId)) {
        const isSession = this.cookies.get('v5_s');
        this.sessionValue = isSession;
        if (isSession) {
          this.checkSession(isSession);
        } else {
          this.sessionValue = '';
          this.setBalanceValue(0);
          this.setLogout();
          this.sessionInterval.unsubscribe();
        }
      }
      return true;
    } else {
      this.setLoginStatus(false);
      this.sessionValue = '';
      this.setBalanceValue(0);
      if (isPlatformBrowser(this.platformId)) {
        this.sessionInterval.unsubscribe();
      }
      return false;
    }
  }

}