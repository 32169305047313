<div class="navigation-title">
    <div class="back-button" (click)="backButton()">
        <fa-icon [icon]="faArrowCircleLeft" [size]="'xl'"></fa-icon>
    </div>
    <div class="title">{{currentTitle}}</div>
</div>
<div class="container-fluid deposit-mobile-component px-0 pt-3 pb-3">
    <div class="main-deposit-mobile">
        @if(loader) {
        <div class="spinner-container">
            <div class="spinner-border" style="margin: 0 auto;color: var(--accent-main-text-color);" role="status"></div>
        </div>
        }
        @if(showError && !showPromo && !loader){
            <div class="page-error">
                <div class="container-xxl m-auto">
                    <div class="row align-items-center container-change-promo d-flex py-5 pt-3 mb-5">
                        <div class="container-payment">
                            <div class="pembayaran-error">
                                <span class="image-success">
                                    <fa-icon [icon]="faShield" [size]="'9x'"></fa-icon>
                                    <fa-icon [icon]="faXmark" [size]="'4x'"></fa-icon>
                                </span>
                                <span class="text-pembayaran">Waktu pembayaran anda sudah habis.<br> Mohon hubungi CS
                                    kami jika anda mengalami kendala.</span>
                            </div>
                            <button type="button" class="btn btn-redirect mt-3" [disabled]="disabledButtonSub"
                                (click)="cancelAutoPayment()">Kembali</button>
                            <div></div>
                        </div>
                    </div>
                </div>
            </div>
        }
        @if(successDeposit && !showError && !loader){
            <div class="page-success">
                <div class="pembayaran-berhasil">
                    <span class="image-success">
                        <fa-icon [icon]="faShield" [size]="'9x'"></fa-icon>
                        <fa-icon [icon]="faCheck" [size]="'4x'"></fa-icon>
                    </span>
                    <span class="text-pembayaran">PEMBAYARAN BERHASIL</span>
                </div>
                <button type="button" class="btn btn-redirect mt-3" [disabled]="disabledButtonSub"
                    (click)="goToTransaction()">Kembali Ke Riwayat
                    Transaksi</button>
                <div></div>
                <button type="button" class="btn btn-auto-redirect" [disabled]="disabledButtonSub">Otomatis kembali ke riwayat
                    transaksi ({{timeCountSecond}}s)</button>
            </div>
        }
        @if(!showError && !successDeposit){
            @if(!showFormPaymentAuto && !showFormPaymentManual &&!loader){
                @if(!nextStepOption){
                <div class="row align-items-center main-deposit py-5 mb-5">
                    <div class="title-page-small h5">
                        Pilih Opsi Pembayaran
                    </div>
                    <div class="option-payment">
                        <div class="auto-button-big mt-4" [ngClass]="currentOptionPayment === 'auto' ? 'active-button':'' "
                            (click)="selectOptionPayment('auto')">
                            <div class="img-button">
                                <img src="../../../template/green/assets/images/auto.png">
                                <img class="img-check" src="../../../template/green/assets/images/check_gold.png">
                            </div>
                            <div class="text-container">
                                <span class="text-main">Auto Deposit</span>
                                <span class="text-sub">Deposit dengan payment gateway</span>
                            </div>
                        </div>
                        <div class="manual-button-big mt-4" [ngClass]="currentOptionPayment === 'manual' ? 'active-button':''"
                            (click)="selectOptionPayment('manual')">
                            <div class="img-button">
                                <img src="../../../template/green/assets/images/manual.png">
                                <img class="img-check" src="../../../template/green/assets/images/check_gold.png">
                            </div>
                            <div class="text-container">
                                <span class="text-main">Manual Deposit</span>
                                <span class="text-sub">Deposit transfer bank dan lainnya melalui admin</span>
                            </div>
                        </div>
                
                    </div>
                    <button type="button" class="fixed-bottom text-uppercase border-0 outline-0 w-100 btn-sign-mobile" (click)="nextButtonOption()">PILIH</button>
                
                </div>
                } @else {
                <div class="row align-items-center main-deposit py-5 mb-5">
                    <div class="title-page-small h5">
                        Pilih Metode Pembayaran
                    </div>
                    <div class="option-payment">
                        <div class="auto-button-big mt-4" [ngClass]="currentMethodPayment === 'vacc' ? 'active-button':'' "
                            (click)="selectMethodPayment('vacc')">
                            <div class="img-button">
                                <img src="../../../template/green/assets/images/vacc.png">
                            </div>
                            <div class="text-container">
                                <span class="text-main">Virtual Account</span>
                                <span class="text-sub">Transfer dengan cepat ke akun bank virtual pilihan anda</span>
                            </div>
                        </div>
                        <div class="manual-button-big mt-4" [ngClass]="currentMethodPayment === 'qris' ? 'active-button':''"
                            (click)="selectMethodPayment('qris')">
                            <div class="img-button">
                                <img src="../../../template/green/assets/images/qris.png">
                            </div>
                            <div class="text-container">
                                <span class="text-main">QRIS</span>
                                <span class="text-sub">Pembayaran cepat dan mudah dengan scan barcode QRIS</span>
                            </div>
                        </div>
                
                    </div>
                    <button type="button" class="fixed-bottom text-uppercase border-0 outline-0 w-100 btn-sign-mobile" (click)="nextButtonMethod()">PILIH</button>
                
                </div>
                }
            }@else{
                @if(!hideSwitchMenu && depositConfig['status'] && !showConfirmPayment){
                    <div class="container-menu-option">
                        <div class="small-option-payment">
                            <div class="auto-button-small mb-4" [ngClass]="currentOptionPayment === 'auto' ? 'active-button':'' "
                                (click)="selectOptionPayment('auto'); showFormPaymentAuto = true">
                                <div class="text-container">
                                    <span class="text-main">Bayar Otomatis</span>
                                </div>
                            </div>
                            <div class="manual-button-small mb-4" [ngClass]="currentOptionPayment === 'manual' ? 'active-button':''"
                                (click)="selectOptionPayment('manual'); showFormPaymentManual = true">
                                <div class="text-container">
                                    <span class="text-main">Bayar Manual</span>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                @if(loader) {
                <div class="spinner-container">
                    <div class="spinner-border" style="margin: 0 auto;color: var(--accent-main-text-color);" role="status"></div>
                </div>
                }@else{
                    <!--auto payment-->
                    @if(showFormPaymentAuto && currentOptionPayment ==='auto'){
                        <div class="container-auto-dp">
                            @if(!showConfirmPayment){
                                <div class="sub-menu-deposit">
                                    @if(depositConfig['qris']){
                                    <div class="pay-qris" [ngClass]="currentMethodPayment === 'qris' ? 'active-button':''"
                                        (click)="selectMethodPayment('qris')">
                                        <span class="img-icon">
                                            <img src="../../../template/green/assets/images/qris.png">
                                        </span>
                                        <span class="text-menu">Pay with QRIS</span>
                                    </div>
                                    }
                                    @if(depositConfig['virtual_account']){
                                    <div class="pay-vacc" [ngClass]="currentMethodPayment === 'vacc' ? 'active-button':''"
                                        (click)="selectMethodPayment('vacc')">
                                        <span class="img-icon">
                                            <img src="../../../template/green/assets/images/vacc.png">
                                        </span>
                                        <span class="text-menu">Pay with VA</span>
                                    </div>
                                    }
                                </div>
                            }
                            @if(currentMethodPayment === "vacc"){
    
                            }@else{
                                @if(!showConfirmPayment){
                                    <div class="input-amount">
                                        <label class="label-input">Masukan Nominal Deposit</label>
                                        <div class="d-inline-block w-100">
                                            <div class="container-input"
                                                [ngStyle]="{'border-color':isCorrectValue !== 0 ? 'red':'rgba(255, 255, 255, 0.5)'}">
                                                <input id="inputAmount" type="text" spellcheck="false"
                                                    oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                                                    class="form-control" placeholder=" Masukan Nominal " [ngModel]="(inputAmount | currency:' ' : 'symbol' : '1.0-0')"
                                                    (ngModelChange)="updateValueNominal($event)" name="inputAmount" aria-label="Masukan Nominal" />
                                            </div>
                                            <div class="chip-amount-container">
                                                <swiper-container appSwiper [config]="config" class="swiper-container">
                                                    @for (item of arrayNominal; track item;) {
                                                    <swiper-slide class="swiper-item">
                                                        <div class="chip-amount" [ngClass]="currentNominal === item ? 'active-chip':''"
                                                            (click)="setNominalChip(item)">Rp {{item | currency:' ' : 'symbol' : '1.0-0'}}</div>
                                                    </swiper-slide>
                                                    }
                                                </swiper-container>
                                            </div>
        
                                            <div class="container-rule-payment">
                                                <span>
                                                    <fa-icon [icon]="faCircleInfo"></fa-icon>
                                                    @if(depositConfig['handled_by'] === 1){
                                                        @if(currentMethodPayment ==="qris"){
                                                            Transaction Fee {{depositConfig['qris_fee']*100}}% {{depositConfig['qris_fee_fix_amount'] == 0 ? '':'+ '+(depositConfig['qris_fee_fix_amount'] | currency:' ' : 'symbol' : '1.0-0')}}
                                                        }@else{
                                                            Transaction Fee {{depositConfig['virtual_account_fee']*100}}% {{depositConfig['virtual_account_fee_fix_amount'] == 0 ? '':'+ '+(depositConfig['virtual_account_fee_fix_amount'] | currency:' ' : 'symbol' : '1.0-0')}}
                                                        }
                                                    } @else {
                                                        Transaction Fee FREE
                                                    }
                                                </span>
                                                <span>
                                                    <fa-icon [icon]="faCircleInfo"></fa-icon>
                                                    Minimum Jumlah Deposit 10.000
                                                </span>
                                                <span>
                                                    <fa-icon [icon]="faCircleInfo"></fa-icon>
                                                    Maksimum Jumlah Deposit 10.000.000
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="container-rek-anda">
                                    <div class="container-box-bottom">
                                        <div class="container-payment-details">
                                            <div class="container-amount-promo">
                                                <div class="choose-promo d-custom-height-none d-custom-height-normal-flex">
                                                    <div class="d-inline-block w-100">
                                                        @if (currentPromo['_id']) {
                                                        <div class="container-promo-bar active-promo" [ngClass]="disabledButton? 'pointer-off':''">
                                                            <div class="area-click" (click)="openPromoSearch()">
                                                                <span class="img-icon">
                                                                    <img src="../../../template/green/assets/images/voucher_applied.png">
                                                                </span>
                                                                <!-- <span class="text-status display-600-none">Voucher Applied</span> -->
                                                                <span class="text-name-promo">{{currentPromo['title']}}</span>
                                                            </div>
                                                            <fa-icon [icon]="faTrashCan" (click)="resetPromo()"></fa-icon>
                                                        </div>
                                                        } @else {
                                                        <div class="container-promo-bar" [ngClass]="disabledButton? 'pointer-off':''" (click)="openPromoSearch()">
                                                            <span class="img-icon">
                                                                <img src="../../../template/green/assets/images/voucher.png">
                                                            </span>
                                                            <span class="text-status">Voucher Promo</span>
                                                            <span>Pilih Voucher</span>
                                                            <!-- <fa-icon [icon]="faTrashCan"></fa-icon> -->
                                                        </div>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="container-payment-detail">
                                                <div class="jumlah-deposit">
                                                    <span>Jumlah Deposit</span>
                                                    <span>Rp {{inputAmount ? (inputAmount | currency:' ' : 'symbol' : '1.0-0') : 0}}</span>
                                                </div>
                                                <div class="biaya-transaksi">
                                                    <span>Biaya Transaksi</span>
                                                    @if(depositConfig['handled_by'] === 1){
                                                    @if(currentMethodPayment ==="qris"){
                                                    <span>IDR {{inputAmount ? ((inputAmount * (depositConfig['qris_fee']))+depositConfig['qris_fee_fix_amount'] | currency:'
                                                        ' : 'symbol' : '1.0-0') : 0}}</span>
                                                    }@else{
                                                    <span>IDR {{inputAmount ? ((inputAmount *
                                                        (depositConfig['virtual_account_fee']))+depositConfig['virtual_account_fee_fix_amount'] | currency:' ' : 'symbol' :
                                                        '1.0-0') :
                                                        0}}</span>
                                                    }
                                                    } @else {
                                                    FREE
                                                    }
                                                </div>
                                                <div class="total-diterima">
                                                    <span>TOTAL DITERIMA</span>
                                                    @if(depositConfig['handled_by'] === 1){
                                                    @if(currentMethodPayment ==="qris"){
                                                    <span>IDR {{inputAmount ? (inputAmount-((inputAmount *
                                                        (depositConfig['qris_fee']))+depositConfig['qris_fee_fix_amount']) | currency:'
                                                        ' : 'symbol' : '1.0-0') : 0}}</span>
                                                    }@else{
                                                    <span>IDR {{inputAmount ? (inputAmount-((inputAmount *
                                                        (depositConfig['virtual_account_fee']))+depositConfig['virtual_account_fee_fix_amount']) | currency:' ' : 'symbol' :
                                                        '1.0-0') :
                                                        0}}</span>
                                                    }
                                                    }@else{
                                                    <span>IDR {{inputAmount ? (inputAmount | currency:' ' : 'symbol' : '1.0-0') : 0}}</span>
                                                    }
                                                </div>
                                                <div id="deposit-button" class="text-uppercase track-deposit border-0 outline-0 w-100 btn-deposit-manual" [ngClass]="disabledButton? 'pointer-off':''"
                                                    (click)="confirmDeposit()">{{inProgress ? 'DALAM PROSES':'DEPOSIT'}}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                }@else{
                                    <div class="container-detail-transfer">
                                        <span class="title-deposit-payment">HARAP SEGERA MELAKUKAN PEMBAYARAN</span>
                                        <div class="area-qris-payment">
                                            <div class="qris-image">
                                                <!-- <qrcode [imageSrc]="websiteJSONData['header']['favicon']" [imageHeight]="50" [imageWidth]="50" (qrCodeURL)="onChangeURL($event)" [qrdata]="autoData['paymentgateway_link']" [width]="256" [errorCorrectionLevel]="'M'">
                                                                                                </qrcode> -->
                                                <qrcode (qrCodeURL)="onChangeURL($event)" [qrdata]="autoData['paymentgateway_link']" [width]="210"
                                                    [imageHeight]="210" [errorCorrectionLevel]="'M'">
                                                </qrcode>
                                                <!-- <qrcode (qrCodeURL)="onChangeURL($event)" [qrdata]="autoData['paymentgateway_link']" [width]="210"
                                                    [imageHeight]="210" [errorCorrectionLevel]="'M'">
                                                </qrcode> -->
                                        
                                                <a [href]="qrCodeDownloadLink" download="qrcode" class="btn btn-download-qr mt-1">Download QR</a>
                                            </div>
                                            <div class="area-detail-container">
                                                <div class="area-detail-payment">
                                                    <div class="container-detail">
                                                        <div class="qris-detail">
                                                            <span class="user-name">{{ autoData['paymentgateway_store_name'] ? autoData['paymentgateway_store_name'] : autoData['paymentgateway_name']}}</span>
                                                        </div>
                                                        <div class="transfer-detail">
                                                            <div class="nominal-transfer">IDR {{autoData ? (autoData['amount'] | currency:' ' : 'symbol' :
                                                                '1.0-0') : 0}}</div>
                                                        </div>
                                                        <div class="time-expired">
                                                            Berlaku Hingga <span class="count-time">({{ minutesShow }}:{{ secondsShow }})</span>
                                                        </div>
                                                    </div>
                                                    
                                                </div>
                                        
                                            </div>
                                        </div>
                                        <div class="container-button-qris text-uppercase border-0 outline-0 w-100 btn-deposit-manual">
                                            <button type="button" class="btn btn-cancel-deposit" [disabled]="disabledButtonSub"
                                                (click)="cancelAutoPayment()">Batalkan Pembayaran</button>
                                            <button type="button" class="btn btn-check-deposit" [disabled]="disabledButtonSub"
                                                (click)="checkStatusDepositAuto()">Konfirmasi Pembayaran</button>
                                        </div>
                                    </div>
                                }
                            }
                        </div>
                    }
                    <!--end auto payment-->
        
                    @if(showFormPaymentManual && currentOptionPayment === "manual" && !loader){
                        <app-deposit-manual-mobile></app-deposit-manual-mobile>
                    }
                }
            }
        }
    </div>
</div>

@if(showPromo && !showError && !loader){
<div class="page-change-payment">
    <div class="container-xxl m-auto">
        <div class="row align-items-center container-change-promo py-5 pt-3 mb-5">
            <div class="title-page-small h5">
                Pilih Promosi
            </div>
            <div class="container-input-filter">
                <div class="input-filter">
                    <form (ngSubmit)="inputSearchPromo()">
                        <input class="form-control" type="text" [formControl]="searchPromo" placeholder="Cari Promosi">
                        <button type="submit" class="btn">
                            <!-- <fa-icon [icon]="faMagnifyingGlass"></fa-icon> -->
                        </button>
                    </form>
                </div>
                <!-- <div ngbDropdown class="position-relative filter-popularity" autoClose="true">
                    <div ngbDropdownMenu class="dropdown-menu select-bank">
                        <div class="d-flex flex-column gap-2">
                            <label ngbDropdownItem for="paymentAll" class="d-flex gap-2"
                                [class.active]="currentSelectPaymentType === 'all'"
                                (click)="onSelectDropdownValue('all')">
                                Tampilkan Semua
                            </label>
                            <label ngbDropdownItem class="d-flex gap-2"
                                [class.active]="currentSelectPaymentType === 'deposit'"
                                (click)="onSelectDropdownValue('deposit')">
                                Deposit Promo
                            </label>
                            <label ngbDropdownItem class="d-flex gap-2"
                                [class.active]="currentSelectPaymentType === 'cashback'"
                                (click)="onSelectDropdownValue('cashback')">
                                Cashback Promo
                            </label>
                        </div>
                    </div>
                </div> -->
            </div>
            <div class="container-list-dynamic">
                <div class="container-list-promo">
                    @for (item of promoSearch; track $index) {
                    <div class="card-promo" (click)="currentPromo = item; selectPromo()">
                        @if(item){
                            @if(item['type'] === 'deposit'){
                                <div class="type-promo">DEPOSIT</div>
                                <div class="title-promo deposit">{{item['title']}}</div>
                                <img src="../../../template/green/assets/images/deposit_promo.png" />
                                @if(currentPromo['_id'] === item['_id']){
                                <fa-icon [icon]="faCircleCheck" [size]="'2x'"></fa-icon>
                                }
                            }
                            @if(item['type'] === 'cashback'){
                                <div class="type-promo">CASHBACK</div>
                                <div class="title-promo cashback">{{item['title']}}</div>
                                <img src="../../../template/green/assets/images/cashback_promo.png" />
                                @if(currentPromo['_id'] === item['_id']){
                                <fa-icon [icon]="faCircleCheck" [size]="'2x'"></fa-icon>
                                }
                            }
                        }
                    </div>
                    }
                </div>
            </div>
            <!-- <div class="container-buttons">
                <div class="btn btn-create-rek" (click)="showPromo=false">Batal</div>
                <div class="btn btn-select-rek" (click)="selectPromo()">Pilih Promo</div>
            </div> -->
        </div>
    </div>
</div>
<div class="container-pin">
    <div class=""></div>
</div>
}